'use client'

import * as React from 'react'
import Menu from './components/Menu'
import Coupon from '@/app/(client-layout)/components/Coupon/coupon'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default ((): React.ReactElement => {
  return (
    <>
      <Coupon />
      <header className="relative px-4 py-8 md:px-6 lg:px-8 xl:px-4">
        <Menu />
      </header>
    </>
  )
}) satisfies React.FC<Props>
