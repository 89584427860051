'use client'

import * as React from 'react'
import Link from 'next/link'
import moment from 'moment-timezone'

moment.tz.setDefault('America/New_York')

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default ((): React.ReactElement => {
  const [showCookieDialog, setShowCookieDialog] = React.useState<boolean>(false)

  React.useEffect(() => {
    const localStorageCookieConsentAccepted = localStorage.getItem('cookieConsentAccepted')

    if (localStorageCookieConsentAccepted === null) {
      setShowCookieDialog(true)
    }
  }, [])

  const acceptCookies = (): void => {
    localStorage.setItem('cookieConsentAccepted', 'true')
    localStorage.setItem('cookieConsentExpiration', moment().add(1, 'year').toISOString())

    setShowCookieDialog(false)
  }

  const rejectCookies = (): void => {
    localStorage.setItem('cookieConsentAccepted', 'false')
    localStorage.setItem('cookieConsentExpiration', moment().add(1, 'day').toISOString())

    setShowCookieDialog(false)
  }

  React.useEffect(() => {
    const expiration = localStorage.getItem('cookieConsentExpiration')

    if (!expiration) {
      return
    }

    if (moment().isSameOrAfter(moment(expiration))) {
      setShowCookieDialog(true)

      localStorage.removeItem('cookieConsentAccepted')
      localStorage.removeItem('cookieConsentExpiration')
    }
  }, [])

  return (
    <div>
      {showCookieDialog && (
        <div className="border-default bg-light fixed inset-x-4 bottom-6 max-w-sm rounded-lg border p-6 pb-4 shadow-lg sm:inset-x-auto sm:right-6 sm:mx-auto sm:max-w-lg">
          <h3 className="mb-2 font-semibold">We use cookies</h3>
          <div className="mb-4">
            <p className="text-sm">
              This website uses cookies to ensure you get the best experience on our website. By continuing to use this site, you agree to
              our use of cookies as described in our{' '}
              <Link href="/cookie-policy" className="text-primary hover:text-default">
                Cookie Policy
              </Link>
              . You can change your cookie settings at any time by adjusting your browser settings.
            </p>
          </div>
          <div className="border-default flex justify-between border-t pt-4">
            <div>
              <button
                type="button"
                className="bg-primary border-primary hover:bg-secondary mr-4 rounded border px-4 py-2 text-sm text-white transition-all duration-300 ease-in-out"
                onClick={acceptCookies}
              >
                Accept all
              </button>
              <button
                type="button"
                className="bg-primary border-primary hover:bg-secondary rounded border px-4 py-2 text-sm text-white transition-all duration-300 ease-in-out"
                onClick={rejectCookies}
              >
                Reject all
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}) satisfies React.FC<Props>
