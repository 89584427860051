'use client'

import * as React from 'react'
import Image from 'next/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faX } from '@fortawesome/free-solid-svg-icons'
import Link from 'next/link'
import { useAppDispatch, useAppSelector } from '@/lib/store'
import { getCurrentUser, getCurrentUserIsLoggedIn } from '@/lib/features/currentUser/currentUserSlice'
import { setIsUserMenuOpen, setIsMobileUserMenuOpen, getIsMobileUserMenuOpen } from '@/lib/features/userMenu/userMenuSlice'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const menuOpenStyle = (isMobileUserMenuOpen: boolean, zIndex: number): React.CSSProperties => ({
  zIndex: isMobileUserMenuOpen ? zIndex : 'auto',
})

export default ((): React.ReactElement => {
  const user = useAppSelector(getCurrentUser)
  const isLoggedIn = useAppSelector(getCurrentUserIsLoggedIn)
  const isMobileUserMenuOpen = useAppSelector(getIsMobileUserMenuOpen)
  const dispatch = useAppDispatch()

  const headerMenu = [
    { label: 'Home', link: '/' },
    { label: 'Explore', link: '/explore' },
    { label: 'FAQ', link: '/#faq' },
    { label: 'API', link: `${process.env['NEXT_PUBLIC_SVGIO_API_URL']}/v1/docs` },
    { label: 'Contact', link: '/contact' },
    { label: 'Login', link: '/login' },
  ]

  // If user is not null (logged in), hide the Pricing link
  if (!isLoggedIn || !user?.user_membership) {
    headerMenu.splice(3, 0, { label: 'Pricing', link: '/pricing' })
  }

  React.useEffect(() => {
    const handleResize = (): void => {
      if (window.innerWidth >= 768) {
        dispatch(setIsMobileUserMenuOpen(false))
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [dispatch])

  const toggleMobileMenu = (): void => {
    dispatch(setIsMobileUserMenuOpen(!isMobileUserMenuOpen))
  }

  const closeMenu = (): void => {
    dispatch(setIsUserMenuOpen(false))
    dispatch(setIsMobileUserMenuOpen(false))
  }

  React.useEffect(() => {
    const { body } = document

    if (isMobileUserMenuOpen) {
      body.style.overflow = 'hidden'
    } else {
      body.style.overflow = 'auto'
    }

    return () => {
      body.style.overflow = 'auto'
    }
  }, [isMobileUserMenuOpen])

  return (
    <div className="container relative mx-auto flex items-center justify-between">
      <div className="flex items-center" style={menuOpenStyle(isMobileUserMenuOpen, 2)}>
        <Link onClick={closeMenu} href="/" title="Text prompt to SVG" className="inline-block">
          <Image src="/images/logo.svg" className="h-10 w-32 sm:w-48" width={192} height={40} alt="Text prompt to SVG" />
        </Link>
      </div>

      <nav
        className={`flex ${isMobileUserMenuOpen ? 'fixed inset-x-0 top-0 z-[1] h-full min-h-screen bg-white px-4 pt-28 md:px-6 lg:hidden lg:px-8' : 'hidden lg:flex'}`}
      >
        <ul className={`flex ${isMobileUserMenuOpen ? 'w-full flex-col space-x-0 space-y-4' : 'space-x-10'}`}>
          {headerMenu.map((menu) => (
            <li key={`header-menu-${menu.label}`}>
              {menu.label === 'Login' ? (
                <div>
                  {isLoggedIn ? (
                    <Link href="/generate" onClick={closeMenu}>
                      <span className="bg-primary hover:bg-secondary mt-2 inline-block rounded px-4 py-2 text-white transition-all duration-300 ease-in-out lg:inline">
                        My account
                      </span>
                    </Link>
                  ) : (
                    <Link
                      href={menu.link}
                      className="bg-primary hover:bg-secondary inline-block rounded px-4 py-2 text-white transition-all duration-300 ease-in-out lg:inline"
                      onClick={closeMenu}
                    >
                      {menu.label}
                    </Link>
                  )}
                </div>
              ) : (
                <Link href={menu.link} className="hover:text-primary" onClick={closeMenu}>
                  {menu.label}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>

      {/* Mobile menu toggle button */}
      <div className="flex items-center lg:hidden" style={menuOpenStyle(isMobileUserMenuOpen, 1)}>
        <button type="button" onClick={toggleMobileMenu} className="text-black focus:outline-none">
          {isMobileUserMenuOpen ? (
            <FontAwesomeIcon icon={faX} size="lg" className="text-default" />
          ) : (
            <FontAwesomeIcon icon={faBars} size="lg" className="text-default" />
          )}
        </button>
      </div>
    </div>
  )
}) satisfies React.FC<Props>
