'use client'

import Image from 'next/image'
import Link from 'next/link'
import * as React from 'react'
import { useAppSelector } from '@/lib/store'
import { getCurrentUser, getCurrentUserIsLoggedIn } from '@/lib/features/currentUser/currentUserSlice'
import Newsletters from '../Newsletters/newsletters'
import CookieConsent from '@/app/(client-layout)/components/Cookie-consent/cookie-consent'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default ((): React.ReactElement => {
  const user = useAppSelector(getCurrentUser)
  const userIsLoggedIn = useAppSelector(getCurrentUserIsLoggedIn)

  return (
    <footer>
      <div className="bg-primary px-4 py-12 md:px-6 lg:px-8 xl:px-4">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <div className="mb-12 flex w-full flex-wrap sm:w-1/2 md:w-full lg:w-1/2 xl:mb-0 xl:w-2/3 xl:flex-nowrap">
              <div>
                <Link href="/" title="Text prompt to SVG">
                  <Image
                    src="/images/logo-footer.svg"
                    className="h-10 w-32 sm:w-36 md:w-48 lg:w-48 xl:w-80"
                    width={192}
                    height={40}
                    alt="Text prompt to SVG"
                  />
                </Link>
              </div>
              <div className="text-footerLight mt-8 px-0 lg:pr-24 xl:mt-0 xl:px-32">
                <p className="mb-4 hidden font-semibold uppercase xl:block">Newsletter</p>
                <p>
                  Be the first to know! Subscribe for exclusive promotions and stay updated on our latest style launches. Join now and never
                  miss out!
                </p>
                <Newsletters />
              </div>
            </div>
            <div className="flex w-full sm:w-1/2 md:w-full lg:w-1/2 xl:w-1/3">
              <div className="w-full pl-0 lg:pl-12">
                <div className="text-footerLight">
                  <p className="mb-4 font-semibold uppercase">Links</p>
                  <ul className="footer-menu">
                    <li className="mb-1">
                      <Link href="/" className="hover:text-white">
                        Home
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link href="/explore" className="hover:text-white">
                        Explore
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link href="/#faq" className="hover:text-white">
                        FAQ
                      </Link>
                    </li>
                    {!userIsLoggedIn || !user?.user_membership ? (
                      <li className="mb-1">
                        <Link href="/pricing" className="hover:text-white">
                          Pricing
                        </Link>
                      </li>
                    ) : null}
                    <li className="mb-1">
                      <Link href={`${process.env['NEXT_PUBLIC_SVGIO_API_URL']}/v1/docs`} className="hover:text-white">
                        API
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link href="/contact" className="hover:text-white">
                        Contact
                      </Link>
                    </li>
                    <li>
                      {userIsLoggedIn ? (
                        <Link href="/generate" className="hover:text-white">
                          My account
                        </Link>
                      ) : (
                        <Link href="/login">Login</Link>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full">
                <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 xl:col-span-2">
                  <div className="text-footerLight">
                    <p className="mb-4 font-semibold uppercase">Legal</p>
                    <ul className="footer-menu">
                      <li className="mb-1">
                        <Link href="/terms-and-conditions" className="hover:text-white">
                          Terms and conditions
                        </Link>
                      </li>
                      <li className="mb-1">
                        <Link href="/privacy-policy" className="hover:text-white">
                          Privacy policy
                        </Link>
                      </li>
                      <li className="mb-1">
                        <Link href="/cookie-policy" className="hover:text-white">
                          Cookie policy
                        </Link>
                      </li>
                      <li className="mb-1">
                        <Link href="/trademark-disclosure" className="hover:text-white">
                          Trademark disclosure
                        </Link>
                      </li>
                      <li>
                        <Link href="/dmca" className="hover:text-white">
                          Dmca
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto grid px-4 py-3 text-sm xl:px-0">
        <p>All rights reserved. &copy; SVG.io {new Date().getFullYear()}.</p>
      </div>
      <CookieConsent />
    </footer>
  )
}) satisfies React.FC<Props>
