'use client'

import * as React from 'react'
import moment from 'moment-timezone'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGift } from '@fortawesome/free-solid-svg-icons'
import { useAppSelector } from '@/lib/store'
import { getFirstActivePublicCoupon } from '@/lib/features/coupons/couponsSlice'
import { getIsMobileUserMenuOpen } from '@/lib/features/userMenu/userMenuSlice'

moment.tz.setDefault('America/New_York')

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default ((): React.ReactElement | null => {
  const coupon = useAppSelector(getFirstActivePublicCoupon)
  const isMobileUserMenuOpen = useAppSelector(getIsMobileUserMenuOpen)

  if (!coupon) {
    return null
  }

  return (
    <div className={`${isMobileUserMenuOpen ? 'fixed' : ''}`}>
      <Link href="/pricing" className="text-baseSM bg-default inline-block w-full p-2 text-white">
        <div className="flex items-center justify-center">
          <div className="mr-4 flex grow basis-0 justify-end">
            <p className="flex font-bold leading-none sm:leading-5">
              <span className="hidden sm:mr-3 sm:inline-block">
                <FontAwesomeIcon icon={faGift} width={22} height={22} className="size-4 text-white" />
              </span>
              {coupon.name}
            </p>
          </div>
          <span className="border-premium text-premium mr-4 border border-dashed px-3 py-1 text-center font-bold">
            {coupon.percent_off && <p className="leading-none sm:leading-5">{(+coupon.percent_off).toFixed(0)}% OFF</p>}
            {coupon.amount_off && <p className="leading-none sm:leading-5">${coupon.amount_off} OFF</p>}
            {coupon.duration_in_months && (
              <p className="text-xs font-normal leading-none sm:leading-5">for {coupon.duration_in_months} months</p>
            )}
          </span>
          <div className="grow basis-0 text-left text-xs">
            <p>
              Applied automatically at checkout until <b className="font-semibold">{moment(coupon.expires_at).format('MMM D, YYYY')}</b>
            </p>
          </div>
        </div>
      </Link>
    </div>
  )
}) satisfies React.FC<Props>
